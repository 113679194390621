<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <nav-tabs-card>
        <template slot="content">
          <md-tabs class="md-success" md-sync-route>
            <md-tab
              id="tab-cadastro"
              md-label="Cadastro"
              md-icon="account_box"
              v-model="cadastro"
              :to="{ name: 'Novo Cadastro' }"
              :md-active="true"
            >
            </md-tab>
            <md-tab
              id="tab-vinculos"
              md-label="Vinculos"
              md-icon="link"
              v-model="vinculos"
              :to="{ name: 'Novos Vinculos' }"
              :md-active="false"
              :md-disabled="vinculosDisabled"
            >
            </md-tab>
            <!--<md-tab
              id="tab-pensionistas"
              md-label="Pensionistas"
              v-model="pensionistas"
              :to="{ name: 'Novos Pensionistas' }"
              md-icon="local_atm"
              :md-disabled="false"
            >
            </md-tab>-->
            <md-tab
              id="tab-dependentes"
              md-label="Dependentes"
              v-model="dependentes"
              :to="{ name: 'Novos Dependentes' }"
              md-icon="supervisor_account"
              :md-active="false"
              :md-disabled="dependentesDisabled"
            >
            </md-tab>
            <md-tab
              id="tab-enderecos"
              md-label="Endereços"
              v-model="enderecos"
              md-icon="apartment"
              :to="{ name: 'Novos Enderecos' }"
              :md-active="false"
              :md-disabled="enderecosDisabled"
            >
            </md-tab>
            <md-tab
              id="tab-telefones"
              md-label="Telefones"
              v-model="telefones"
              md-icon="call"
              :to="{ name: 'Novos Telefones' }"
              :md-active="false"
              :md-disabled="telefonesDisabled"
            >
            </md-tab>
            <md-tab
              id="tab-emails"
              md-label="Emails"
              v-model="emails"
              md-icon="mail"
              :to="{ name: 'Novos Emails' }"
              :md-active="false"
              :md-disabled="emailsDisabled"
            >
            </md-tab>
            <!--<md-tab
              id="tab-arquivos"
              md-label="Arquivos"
              v-model="arquivos"
              md-icon="description"
              :to="{ name: 'Novos Arquivos' }"
              :md-active="false"
              :md-disabled="arquivosDisabled"
            >
            </md-tab>-->
          </md-tabs>
          <router-view
            :tipoProprietario="tipoProprietario"
            v-on:nextRoute="nextRouteEnabled"
          ></router-view>
        </template>
      </nav-tabs-card>
    </div>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: { NavTabsCard },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      tipoProprietario: "SERVIDOR",
      vinculosDisabled: true,
      enderecosDisabled: true,
      telefonesDisabled: true,
      emailsDisabled: true,
      //pensionistasDisabled: true,
      dependentesDisabled: true,
      arquivosDisabled: true,
      cadastro: null,
      vinculos: null,
      enderecos: null,
      telefones: null,
      emails: null,
      pensionistas: null,
      dependentes: null,
      arquivos: null,
      editionMode: false,
      mensagem: "",
      papel: ""
    };
  },
  beforeMount() {
    this.getPapel();
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    async loadServidorTab() {
      window._Vue.$router.push({ name: `Novo Cadastro` }).catch(err => {
        Swal.fire({
          title: "ATENÇÃO!",
          icon: "warning",
          text: "Serviço indisponivel no momento, por favor Tente mais tarde!",
          customClass: {
            confirmButton: "md-button md-warning btn-fill"
          },
          buttonsStyling: false
        });
        window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
      });
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    onSelect() {
      console.log("item");
    },
    nextRouteEnabled(value) {
      //console.log(value);
      if (value === "vinculos") {
        this.vinculosDisabled = false;
        //} else if (value === "pensionistas") {
        // this.pensionistasDisabled = false;
      } else if (value === "dependentes") {
        this.dependentesDisabled = false;
      } else if (value === "enderecos") {
        this.enderecosDisabled = false;
      } else if (value === "telefones") {
        this.telefonesDisabled = false;
      } else if (value === "emails") {
        this.emailsDisabled = false;
      } else if (value === "arquivos") {
        this.arquivosDisabled = false;
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  mounted() {
    //this.loadServidorTab();
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

::v-deep .md-tabs-navigation {
  padding: 15px 15px 15px 15px;
}
</style>
